<template lang="pug">
  <AddSuportMaterial/>
</template>
<script>
import AddSuportMaterial from '@/components/AddSuportMaterial'
export default {
  components: {
    AddSuportMaterial
  }
}
</script>
